import { CoreModules, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import OnboardingRoutesDefinitions from "./OnboardingRouteDefinitions";

const OnboardingModule = () => {
  // params
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const getCompanyParams = { companyId: currentCompanyId };

  // locals
  const isCurrentUserVerified = useSelector(CoreState.User.selectIsCurrentUserVerified);
  const isCurrentUserMobilePresent = useSelector(CoreState.User.selectCurrentUserHasMobile);
  const company = useSelector((s) => CoreState.Company.selectCompany(s, getCompanyParams));
  const invites = useSelector((s) => CoreState.Membership.selectInviteListByUser(s, { userId }));
  const companyId = currentCompanyId || "x";
  const redirectUrl = `/company/${companyId}/dashboard/new-tasks`;

  const initialDetailsIncomplete = !isCurrentUserMobilePresent || !company;
  const currentUserNotVerified = !isCurrentUserVerified;
  const companyAddressNotPresent = Boolean(!company?.hasPrimaryAddress);
  const serviceAreaNotPresent = true && !invites.length;

  // order of the steps
  const getSteps = () => {
    const ONBOARDING_STEPS = [OnboardingRoutesDefinitions.AWAITING_VERIFICATION, OnboardingRoutesDefinitions.INITIAL_DETAILS];

    if (!invites.length) ONBOARDING_STEPS.push(OnboardingRoutesDefinitions.MORE_COMPANY_DETAILS);
    // if (serviceAreaNotPresent) ONBOARDING_STEPS.push(OnboardingRoutesDefinitions.SERVICE_AREA_SETUP);

    return ONBOARDING_STEPS;
  };

  // determine the initial step
  const getInitialStep = () => {
    if (currentUserNotVerified) return OnboardingRoutesDefinitions.AWAITING_VERIFICATION.path;
    if (initialDetailsIncomplete) return OnboardingRoutesDefinitions.INITIAL_DETAILS.path;
    if (companyAddressNotPresent) return OnboardingRoutesDefinitions.MORE_COMPANY_DETAILS.path;
    // if (serviceAreaNotPresent) return OnboardingRoutesDefinitions.SERVICE_AREA_SETUP.path;

    return OnboardingRoutesDefinitions.ONBOARDING_COMPLETE.path;
  };

  return (
    <CoreModules.Onboarding.OnboardingModule
      routes={OnboardingRoutesDefinitions}
      steps={getSteps()}
      initialStep={getInitialStep()}
      redirectUrl={redirectUrl}
    />
  );
};
export default OnboardingModule