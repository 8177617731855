import { Grow, Box, Card, CardContent, Button, useTheme, Typography } from "@mui/material"
import { Add as AddIcon } from '@mui/icons-material';
import { CoreComponents, OnboardingActions, OnboardingHeader } from "@build-buddy/core";
import { useDispatch } from "common/state/store";

const SetupServiceArea = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <>
      <OnboardingHeader />
      <Grow in={true}>
        <Box sx={{
          maxWidth: 600,
          width: '100%',
          margin: "0 auto"
        }}>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h1">
                Setup Your Service Area
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  mb: 3
                }}
              >
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Select your Service Area*
                </Typography>
                <Typography variant="body2">
                  Select the areas your company can service to ensure you receive relevant quotes.
                </Typography>
                <Button
                  variant="none"
                  onClick={() => { }}
                  sx={{
                    color: theme.palette.primary.main,
                    justifyContent: "flex-start",
                    borderRadius: 0,
                    ml: 2,
                    my: 1,
                  }}
                >
                  <AddIcon sx={{ mr: 1.25 }} />
                  Add a service area
                </Button>
                <Typography variant="body2">
                  Once you have completed setting up a state, you will be given the opportunity to add more states
                </Typography>
              </Box>
              <CoreComponents.Actions
                isLoading={false}
                submitText="Save"
                onSubmitClick={() => {
                  dispatch(OnboardingActions.nextStep());
                }}
              />
            </CardContent>
          </Card>
        </Box>
      </Grow>
    </>
  )
}

export default SetupServiceArea;