import { CoreComponents, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";

type CompanyProfileSettingsFormProps = {
  onClose(): void;
  isLoading: boolean;
}
const CompanyProfileSettingsForm = (props: CompanyProfileSettingsFormProps) => {
  const { onClose, isLoading } = props;

  // params
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const getNotificationSettingListByCompanyParams = { companyId };

  // mutataions
  const [saveNotificationSettingByCompanyAction, saveNotificationSettingByCompanyActionQuery] = CoreState.Settings.saveNotificationSettingByCompany.useMutation();

  // locals
  const companyNotificationSettingIsEnabled = useSelector((s:CoreState.RootState)=> CoreState.Settings.selectNotificationSettingByCompanyIsEnabled(s, getNotificationSettingListByCompanyParams));
  const companyNotificationSetting = useSelector((s: CoreState.RootState) => CoreState.Settings.selectNotificationSettingByCompanyGrouped(s, getNotificationSettingListByCompanyParams))
  const isSaving = Boolean(saveNotificationSettingByCompanyActionQuery.isLoading && !saveNotificationSettingByCompanyActionQuery.isUninitialized);
  const isSuccess = Boolean(saveNotificationSettingByCompanyActionQuery.isSuccess && !saveNotificationSettingByCompanyActionQuery.isUninitialized);

  const handleSubmit = (notificationSetting: CoreState.Common.Subset<CoreState.Settings.NotificationSetting>) => {
    if (!companyId) return;

    saveNotificationSettingByCompanyAction({
      companyId,
      notificationSetting
    })
  }

  return (
    <CoreComponents.SettingFormDialog
      isSaving={isSaving}
      isSuccess={isSuccess}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      notificationSettingGrouped={companyNotificationSetting as CoreState.Settings.GroupedNotificationSettingType}
      isNotificationSettingEnabled={companyNotificationSettingIsEnabled}
    />
  )
}
export default CompanyProfileSettingsForm;