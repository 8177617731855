import { CoreComponents, CoreState } from "@build-buddy/core";
import { Search as SearchIcon } from "@mui/icons-material";
import { Box, MenuItem, TextField } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect, useState } from "react";
import { SelectDashboardJobListByStatusSearchParams, selectGroupedDashboardJobListByState } from "../selectors";
import DashboardBrowseJobListEmpty from "./DashboardBrowseJobListEmpty";
import DashboardBrowseJobListHeader from "./DashboardBrowseJobListHeader";
import DashboardJobListItem from "./DashboardBrowseJobListItem";
import DashboardBrowseJobListSkeleton from "./DashboardBrowseJobListSkeleton";
import useDashboardFilter from "./useDashboardFilter";

const DashboardBrowseJobList = () => {
  // hooks
  const dashboardFilter = useDashboardFilter();

  // Select Current
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);

  // local state
  const [jobsQueryParams, setJobQueryParams] = useState<CommonState.Job.GetJobListParams>({ companyId: "" });
  const [jobsSelectorParams, setJobSelectorParams] = useState<SelectDashboardJobListByStatusSearchParams>({ companyId: "" });

  // queries
  const jobsQuery = CommonState.Job.getJobList.useQuery(jobsQueryParams, { skip: !jobsQueryParams.companyId })
  const companyAddress = CoreState.Address.getAddressByCompany.useQuery({ companyId: currentCompanyId, type: "Primary" });
  const companyState = companyAddress?.data?.state;

  // selector
  const jobs = useSelector((s) => selectGroupedDashboardJobListByState(s, {
    ...jobsQueryParams,
    ...jobsSelectorParams,
    state: companyState
  }))

  // side effects
  useEffect(() => {
    if(!currentCompanyId) return;
    
    const jobQueryParams = {
      companyId: currentCompanyId,
      filters: {
        companyOnlySkills: dashboardFilter.filter.companyOnlySkills,
        companyRadius: dashboardFilter.filter.companyRadius,
        pageNumber: 1,
        pageSize: 1000,
      }
    };
    setJobQueryParams(jobQueryParams)
    setJobSelectorParams({
      ...jobQueryParams,
      search: dashboardFilter.filter.search,
      bookmarked: dashboardFilter.filter.bookmarked,
      status: dashboardFilter.filter.status
    })
  }, [dashboardFilter]);

  return (
    <>
      <Box>
        <TextField
          size="small"
          placeholder="Search quotes"
          sx={{
            width: {
              xs: "100%",
              md: "auto"
            }
          }}
          onChange={(e) => dashboardFilter.setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1 }} />,
          }}
        />
        <CoreComponents.Select
          size="small"
          name="asd"
          displayEmpty
          value={dashboardFilter.filter.companyRadius}
          sx={{ ml: 1 }}
          onChange={(e) => dashboardFilter.setCompanyRadius(e.target.value)}
        >
          <MenuItem value={999999}>All Zones</MenuItem>
          <MenuItem value={50}>50 km radius</MenuItem>
          <MenuItem value={100}>100 km radius</MenuItem>
          <MenuItem value={250}>250 km radius </MenuItem>
        </CoreComponents.Select>
      </Box>

      {Boolean(!jobsQuery.isLoading && jobs.length) &&
        <DashboardBrowseJobListHeader />
      }

      <CoreComponents.PreContent
        isLoading={jobsQuery.isLoading || jobsQuery.isFetching}
        isEmpty={!jobs || !jobs.length}
        empty={<DashboardBrowseJobListEmpty />}
        loader={<DashboardBrowseJobListSkeleton />}
      >
        {jobs?.map((job, idx) => (
          <DashboardJobListItem key={idx} job={job} />
        ))}
      </CoreComponents.PreContent>
    </>
  )
}
export default DashboardBrowseJobList;

