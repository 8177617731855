import { CoreRouting } from "@build-buddy/core";
import Dashboard from "./Dashboard";

const DashboardRouteDefinitions = {
  DASHBOARD_ROOT: {
    path: "/company/:cid/dashboard",
    component: Dashboard,
    exact: true
  },
  DASHBOARD: {
    path: "/company/:cid/dashboard/:filter",
    component: Dashboard,
  }
}

const DashboardRoutes = () => {
  return (
    <CoreRouting.Routes 
      routes={[
        DashboardRouteDefinitions.DASHBOARD_ROOT,
        DashboardRouteDefinitions.DASHBOARD
      ]} 
    />
  );
};

export { DashboardRoutes, DashboardRouteDefinitions };