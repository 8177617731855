import { Route } from "react-router";
import CompanyProfile from "./CompanyProfile";

import CompanyProfileSettingsEdit from "./company-profile-settings/CompanyProfileSettingsEdit";
import CompanyProfileDetailEdit from "./company-profile-detail/CompanyProfileDetailEdit";

const CompanyProfileRouteDefintions = {
  COMPANY_PROFILE: {
    path: "/company/:cid/profile",
    component: CompanyProfile
  },
  COMPANY_PROFILE_EDIT: {
    path: "/company/:cid/profile/edit",
    component: CompanyProfileDetailEdit
  },
  COMPANY_PROFILE_SETTINGS_EDIT:{
    path: "/company/:cid/profile/settings/edit",
    component: CompanyProfileSettingsEdit
  }
}

export { CompanyProfileRouteDefintions };
