import { createSelector } from "@reduxjs/toolkit";
import { CommonState } from "common";
import { GetJobListParams } from "../getJobList";

export interface SelectJobListParams extends GetJobListParams {}
export const selectJobList = createSelector(
  [
    (state, params: SelectJobListParams) => {
      // we need to do this as we need to send undefined to the selector if there are no filters
      // this is cos we dont do this, it will send {} which its trying to use as a cache key
      const filters = params.filters;
      return CommonState.Job.getJobList.select({
        companyId: params.companyId,
        filters: filters && {
          pageNumber: filters.pageNumber,
          pageSize: filters.pageSize,
          companyOnlySkills: filters.companyOnlySkills,
          userOnlySkills: filters.userOnlySkills,
          userRadius: filters.userRadius,
          companyRadius: filters.companyRadius
        }
      }
      )(state)
    }
  ],
  (jobListQuery): Array<CommonState.Job.Job> | undefined => {
    return jobListQuery?.data
  }
)

interface SelectJobListByQuoteRequestParams extends SelectJobListParams {
  quoteRequestId: string;
}
const selectJobListByQuoteRequest = createSelector(
  [
    selectJobList,
    (_, params: SelectJobListByQuoteRequestParams) => ({ quoteRequestId: params.quoteRequestId })
  ],
  (jobList, meta) => {
    if(!jobList) return [];
    return jobList.filter(x => x.quoteRequest.id === meta.quoteRequestId);
  }
)