import { CoreState } from '@build-buddy/core';
import { createSelector } from '@reduxjs/toolkit';

export interface IncompleteProfileItems {
  hasWallet: boolean;
  hasBankAccount: boolean;
  hasAbn: boolean;
  hasBrochure: boolean;
  hasInsurance: boolean;
  hasSkills: boolean;
  userNotificationReviewed: boolean;
  companyNotificationReviewed: boolean;
}

const selectIncompleteProfileItems = createSelector(
  [
    CoreState.Company.selectCompany, 
    CoreState.User.selectCurrentUser, 
    CoreState.Wallet.selectWalletDetails,
    CoreState.Company.selectIsCompanyHaveBrochure
  ],
  (company, user, wallet, hasBrochure): IncompleteProfileItems => {
    const validations = {
      hasWallet: Boolean(wallet?.active),
      hasBankAccount: Boolean(user?.accountNumber),
      hasAbn: Boolean(company?.abn),
      hasBrochure,
      hasInsurance: Boolean(company?.hasInsurance),
      hasSkills: Boolean(company?.hasSkills),
      userNotificationReviewed: Boolean(user?.flags?.notificationReviewed),
      companyNotificationReviewed: Boolean(company?.flags?.notificationReviewed)
    }
    return validations
  }
);

export default selectIncompleteProfileItems;
