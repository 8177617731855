import { CoreRouting, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import CompanyProfileSettingsForm from "./CompanyProfileSettingsForm";

const CompanyProfileSettingsEdit = () => {
  const routing = CoreRouting.useRouting();

  // params
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const getNotificationSettingListByCompanyParams = { companyId };
  const getCompanyProfileParams = { companyId };

  // queries
  const getCompanyQuery = CoreState.Company.getCompany.useQuery(getCompanyProfileParams, { skip: !companyId });
  const getNotificationSettingListByCompanyQuery = CoreState.Settings.getNotificationSettingByCompany.useQuery(getNotificationSettingListByCompanyParams, { skip: !companyId });

  // mutations
  const [updateCompanyAction, updateCompanyQuery] = CoreState.Company.updateCompany.useMutation();

  // selectors
  const company = useSelector((s) => CoreState.Company.selectCompany(s, getCompanyProfileParams));

  // locals
  const isLoading = Boolean(getNotificationSettingListByCompanyQuery.isLoading && !getNotificationSettingListByCompanyQuery.isUninitialized);

  // handlers
  const handleClose = () => {
    if (!company?.flags?.notificationReviewed) {
      updateCompanyAction({
        companyId,
        company: {
          ...company,
          flags: {
            ...company?.flags,
            notificationReviewed: true,
          }
        }
      });
    }
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  return (
    <CompanyProfileSettingsForm
      isLoading={isLoading}
      onClose={handleClose}
    />
  )
}
export default CompanyProfileSettingsEdit;