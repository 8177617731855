import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core';
import { CommonState } from 'common';
import { selectIncompleteProfileItems } from 'common/state/app';
import { IncompleteProfileItems } from 'common/state/app/selectors/selectIncompleteProfileItems';
import { useSelector } from 'common/state/store';
import { CompanyProfileRouteDefintions } from 'modules/company/company-profile/CompanyProfileRoutes';
import { CompanyProfileBrochureRouteDefinitions } from 'modules/company/company-profile/company-profile-brochure/CompanyProfileBrochureRoutes';
import { CompanyProfileInsuranceRoutesDefinitions } from 'modules/company/company-profile/company-profile-insurance/CompanyProfileInsuranceRoutes';
import { CompanyProfileSkillRoutesDefinitions } from 'modules/company/company-profile/company-profile-skill/CompanyProfileSkillRoutes';
import { UserProfileRouteDefinitions } from 'modules/user/profile';
import { UserWalletRouteDefinitions } from 'modules/user/wallet/WalletModule';
import { NotificationsNone as NotificationsNoneIcon} from '@mui/icons-material';

const useIncompleteValidations = (validations: IncompleteProfileItems, isCurrentUserOwner: boolean, isLimited: boolean, routing: any, params: { cid?: string }) => {
  if (!params.cid) return [];

  const incompleteValidations: Array<CoreState.Common.ProfileCompletionCheckItem> = [];

  const addValidationItem = (condition: boolean, item: CoreState.Common.ProfileCompletionCheckItem) => {
    if (condition) {
      incompleteValidations.push(item);
    }
  };

  addValidationItem(
    !validations.hasWallet && isCurrentUserOwner && !isLimited,
    {
      title: "Create Wallet",
      description: 'Setup your wallet to make secure payment transactions.',
      actionLabel: 'Create Wallet',
      handleClick: () => routing.go(UserWalletRouteDefinitions.CREATE_WALLET, { params }),
      icon: <CoreComponents.WalletIcon />
    }
  );

  addValidationItem(
    !validations.hasBankAccount && isCurrentUserOwner && !isLimited,
    {
      title: "Add Bank Account",
      description: 'Add your bank account to make secure payment transactions.',
      actionLabel: 'Add Account',
      handleClick: () => routing.go(UserWalletRouteDefinitions.ADD_BANK_ACCOUNT, { params }),
      icon: <CoreComponents.BankIcon />,
      disableAction: !validations.hasWallet
    }
  );

  addValidationItem(
    !validations.hasAbn,
    {
      title: "Complete Business Profile",
      actionLabel: "Complete Profile",
      handleClick: () => routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE_EDIT, { params }),
      description: "Build trust with a detailed profile featuring skills, experience, testimonials, and licenses.",
      icon: <CoreComponents.CompanyProfileIcon />
    }
  );

  addValidationItem(
    !validations.hasBrochure,
    {
      title: "Add Business Brochure",
      actionLabel: "Add Brochure",
      handleClick: () => routing.go(CompanyProfileBrochureRouteDefinitions.COMPANY_PROFILE_BROCHURE_EDIT, { params }),
      description: "Build trust with a detailed profile featuring skills, experience, testimonials, and licenses.",
      icon: <CoreComponents.CompanyProfileIcon />
    }
  );

  addValidationItem(
    !validations.hasSkills,
    {
      title: "Add Skills",
      actionLabel: "Add Skills",
      handleClick: () => routing.go(CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_ADD, { params }),
      description: "Add your skills and experience to help potential clients find you.",
      icon: <CoreComponents.SkillsIcon />
    }
  );

  addValidationItem(
    !validations.hasInsurance,
    {
      title: "Add Insurance",
      actionLabel: "Add Insurance",
      handleClick: () => routing.go(CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_ADD, { params }),
      description: "Add your insurance details to help potential clients find you.",
      icon: <CoreComponents.InsuranceIcon />
    }
  );

  addValidationItem(
    !validations.userNotificationReviewed && !isLimited,
    {
      title: "Review User Notifications",
      description: 'Review your notifications settings to customise.',
      actionLabel: 'Review Notifications',
      handleClick: () => routing.go(UserProfileRouteDefinitions.USER_PROFILE_SETTINGS_EDIT),
      icon: <NotificationsNoneIcon sx={{fontSize: '25px'}}/>,
      disableAction: false
    }
  )

  addValidationItem(
    !validations.companyNotificationReviewed && !isLimited,
    {
      title: "Review Company Notifications",
      description: 'Review your notifications settings to customise.',
      actionLabel: 'Review Company Notifications',
      handleClick: () => routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE_SETTINGS_EDIT),
      icon: <NotificationsNoneIcon sx={{fontSize: '25px'}}/>,
      disableAction: false
    }
  )

  return incompleteValidations;
};

const DashboardProfileCheck = () => {
  const routing = CoreRouting.useRouting();

  // Selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);
  const validations = useSelector((s) => selectIncompleteProfileItems(s, { companyId: currentCompanyId, userId }));
  const isLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  // params
  const params = { cid: currentCompanyId }

  // Queries
  const getUserQuery = CoreState.User.getUser.useQuery({ userId });
  const getFinancialsDetails = CoreState.Wallet.getFinancialDetails.useQuery({ userId })
  const getBrochureByCompanyQuery = CoreState.Company.getBrochureByCompany.useQuery({ companyId: currentCompanyId });

  // Incomplete profile items
  const incompleteValidations = useIncompleteValidations(validations, isCurrentUserOwner, isLimited, routing, params);

  // Loading
  const isLoading = getUserQuery.isLoading || getFinancialsDetails.isLoading || getBrochureByCompanyQuery.isLoading;

  return (
    <CoreComponents.ProfileCompletionChecker
      validations={validations as any}
      incompleteValidationItems={incompleteValidations}
      loading={isLoading}
    />
  )
}

export default DashboardProfileCheck