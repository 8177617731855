import { CoreModules, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";

const DashboardCompany = () => {
  // selectors
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const companyHasAvatar = useSelector((s) => CoreState.Company.selectCompanyHasAvatar(s, { companyId: currentCompanyId }));

  // queries
  const companyDetails = CoreState.Company.getCompany.useQuery({ companyId: currentCompanyId });
  const companyLogoQuery = CoreState.File.getAvatarByCompany.useQuery({ companyId: currentCompanyId }, { skip: (!currentCompanyId || !companyHasAvatar) });

  // locals
  const companyName = companyDetails?.data?.name || "";

  return (
    <CoreModules.Dashboard.DashboardProjectCard
      label={companyName}
      showActions={false}
      uploadAvatar={false}
      avatarSrc={(companyLogoQuery as any).data?.logo}
    >
      <>
        {/* TODO: Add back the UI and integrate actual functionality to add website when it was not added while onboarding */}
        {/* <Stack direction="row" alignItems="center" sx={{ mb: 1.25 }}>
          <StarIcon sx={{ color: theme.palette.primary.main, mr: 0.75, position: "relative", bottom: 1 }} />
          <Typography variant="body1"> No Reviews </Typography>
        </Stack>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 0.5 }}> Profile completenes: 80% </Typography>
          <LinearProgress variant="determinate" value={80} sx={{ height: "10px", borderRadius: 4 }} />
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}> Elevate your profile to the next level to attract more customers! </Typography> */}


        {/* <Stack direction="row" alignItems="center" sx={{ background: theme.palette.orange[200], p: 1, mb: 1 }}>
          <GlobeIcon sx={{ mr: 0.75, position: "relative", bottom: 1 }} />
          <Typography variant="body2" sx={{ flex: 1, fontWeight: "bold" }}> Add a Website </Typography>
          <ChevronRightIcon />
        </Stack> */}

        {/* <Stack direction="row" alignItems="center" sx={{ background: theme.palette.green[200], p: 1 }}>
          <PlaylistAddCheckIcon sx={{ mr: 0.75, position: "relative", bottom: 1 }} />
          <Typography variant="body2" sx={{ flex: 1, fontWeight: "bold", mr: 0.75 }}> 2 completed tasks are pending reviews from customers. </Typography>
          <ChevronRightIcon />
        </Stack> */}

      </>

    </CoreModules.Dashboard.DashboardProjectCard>
  )
}
export default DashboardCompany;

