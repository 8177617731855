import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { Route, Switch } from "react-router-dom";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";

const CompanyProfileSettings = () => {
  const routing = CoreRouting.useRouting();

  // params
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const getNotificationSettingListByCompanyParams = { companyId };

  // queries
  const getNotificationSettingListByCompanyQuery = CoreState.Settings.getNotificationSettingByCompany.useQuery( getNotificationSettingListByCompanyParams, { skip: !companyId });

  // locals
  const companyNotification = useSelector((s) => CoreState.Settings.selectNotificationSettingByCompany(s, getNotificationSettingListByCompanyParams))
  const label = Boolean(companyNotification?.isEnabled) ? `Yes - ${companyNotification?.frequency}` : "No notifications"

  const handleChange = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE_SETTINGS_EDIT, { params: { cid: companyId } });
  }

  return (
    <>
      <CoreComponents.SettingAccordion
        label={label}
        handleChange={handleChange}
      />
      <Switch>
        <Route
          path={CompanyProfileRouteDefintions.COMPANY_PROFILE_SETTINGS_EDIT.path}
          component={CompanyProfileRouteDefintions.COMPANY_PROFILE_SETTINGS_EDIT.component}
        />
      </Switch>
    </>
  )
}
export default CompanyProfileSettings;